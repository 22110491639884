import { useState, useEffect, useContext } from "react";
import Plus from "../../components/icons/plus.svg";
import Minus from "../../components/icons/minus.svg";
import Trash from "../../components/icons/trash-can.svg";
import Pipe from "../../components/icons/pipe.svg";
import styles from "./AddToCart.module.scss";
import { formatCurrency } from "../../utils/formatCurrency";
import { useShoppingCart } from "../../context/cart";
import StoreContext from "../../context/store";
import { useCurrentRefinements } from "react-instantsearch-hooks-web";

const WEIGHT_ABBREVIATIONS = {
  "half gram": "0.5g",
  "gram": "1g",
  "two gram": "2g",
  "eighth ounce": "3.5g",
  "quarter ounce": "7g",
  "half ounce": "14g",
  "ounce": "28g",
  "default": "each",
};

const abbreviateWeight = (weight) =>
  WEIGHT_ABBREVIATIONS[weight] || WEIGHT_ABBREVIATIONS["default"];

// Default to "eighth ounce" if available, otherwise pick the first option
const getDefaultWeight = (availableWeights) => {
  const preferredWeight = "eighth ounce";
  if (availableWeights.includes(preferredWeight)) return preferredWeight;
  return availableWeights.length > 0 ? availableWeights[0] : "each";
};

export default function Price({ hit, className, weight: initialWeight, setWeight }) {
  const { items } = useCurrentRefinements();

  // Utility functions to compute dynamic price keys
  const toWeightKey = (weight) => weight ? `price_${weight.replace(/ /g, "_")}` : "";
  const toDiscountedKey = (weight) => weight ? `discounted_price_${weight.replace(/ /g, "_")}` : "";

  // Determine the most appropriate initial weight from:
  // 1. Algolia refinements
  // 2. Initial prop
  // 3. Default fallback
  const getInitialWeight = () => {
    const refinement = items.find((item) => item.attribute === "available_weights");
    const refined = refinement?.refinements?.[0]?.label;
    if (refined && hit.available_weights.includes(refined)) return refined;
    if (initialWeight && hit.available_weights.includes(initialWeight)) return initialWeight;
    return getDefaultWeight(hit.available_weights);
  };

  // selected weight and price based on that selection
  const [selectedWeight, setSelectedWeight] = useState(getInitialWeight());
  const [price, setPrice] = useState(hit[toWeightKey(selectedWeight)] || hit.price_each);
  const [discountedPrice, setDiscountedPrice] = useState(hit[toDiscountedKey(selectedWeight)] || hit.discounted_price_each);

  // Update weight and prices when dependencies change
  useEffect(() => {
    const newWeight = getInitialWeight();
    setSelectedWeight(newWeight);
    setPrice(hit[toWeightKey(newWeight)] || hit.price_each);
    setDiscountedPrice(hit[toDiscountedKey(newWeight)] || hit.discounted_price_each);
  }, [items, initialWeight, hit]);

  // Handle manual weight selection
  const handleChange = (e) => {
    const newWeight = e.target.value;
    setSelectedWeight(newWeight);
    setWeight?.(newWeight); // Send selection to parent if needed
    setPrice(hit[toWeightKey(newWeight)] || hit.price_each);
    setDiscountedPrice(hit[toDiscountedKey(newWeight)] || hit.discounted_price_each);
  };

  return (
    <div className={styles[className]}>
      <div className={styles.addToCart}>
        <div className={styles.priceWeight}>
          {/* Original Price if discounted */}
          <div>
            {discountedPrice && (
              <span className={styles.originalPrice}>{formatCurrency(price)}</span>
            )}
          </div>

          {/* Main price and weight selector */}
          <div>
            <span className={`${styles.price} ${discountedPrice ? styles.discountedPrice : ""}`}>
              {formatCurrency(discountedPrice ?? price)}
            </span>

            {hit.available_weights.length > 1 ? (
              <>
                <label htmlFor={hit.product_id} className="visually-hidden">Select Weight:</label>
                <select
                  id={hit.product_id}
                  name="weight"
                  className={styles.weight_select}
                  value={selectedWeight}
                  onChange={handleChange}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  {hit.available_weights.map((weight) => (
                    <option key={weight} value={weight}>
                      {hit.amount || abbreviateWeight(weight)}
                    </option>
                  ))}
                </select>
              </>
            ) : (
              <span className={styles.weight}>
                <Pipe />
                {hit.amount || abbreviateWeight(hit.available_weights[0])}
              </span>
            )}
          </div>
        </div>

        {/* Add-to-cart logic */}
        <AddToCartButton
          product_id={hit.product_id}
          max_cart_quantity={hit.max_cart_quantity}
          selectedWeight={selectedWeight?.replace(/ /g, "_")}
        />
      </div>
    </div>
  );
}

// Renders the cart button logic: add/remove, modify, quantity
function AddToCartButton({ product_id, max_cart_quantity, selectedWeight }) {
  const {
    getItemQuantity,
    increaseCartQuantity,
    decreaseCartQuantity,
    removeFromCart,
    setCartModal,
  } = useShoppingCart();

  const { orderType, setModal } = useContext(StoreContext);
  const [loadButton, setLoadButton] = useState(false);

  useEffect(() => {
    setLoadButton(true);
  }, []);

  const quantity = getItemQuantity(product_id, selectedWeight);

  const handleClick = (e) => {
    if (orderType === "Browsing") {
      setModal(true);
    } else {
      e.preventDefault();
      e.stopPropagation();
      increaseCartQuantity(product_id, selectedWeight);
      setCartModal(true);
    }
  };

  return (
    <div className={styles.buttonContainer}>
      {loadButton ? (
        quantity === 0 ? (
          <button
            className={`openLocationButton ${styles.add_to_bag}`}
            aria-label={orderType !== "Browsing" ? "Add to Bag" : "Check Availability"}
            onClick={handleClick}
          >
            {orderType !== "Browsing" ? "Add to Bag" : "Check Availability"}
          </button>
        ) : (
          <div className={styles.modify_cart} onClick={(e) => e.stopPropagation()}>
            {quantity === 1 ? (
              <button
                className={styles.trash}
                onClick={() => removeFromCart(product_id, selectedWeight)}
                aria-label="Remove item"
              >
                <Trash />
              </button>
            ) : (
              <button
                className={quantity < 1 ? styles.deactive : null}
                onClick={() => decreaseCartQuantity(product_id, selectedWeight)}
                aria-label="Decrease amount"
              >
                <Minus />
              </button>
            )}
            {quantity} in bag
            <button
              className={quantity === max_cart_quantity ? styles.deactive : null}
              onClick={() => increaseCartQuantity(product_id, selectedWeight)}
              aria-label="Increase amount"
            >
              <Plus />
            </button>
          </div>
        )
      ) : (
        <button
          className={`openLocationButton ${styles.add_to_bag}`}
          aria-label="Loading..."
          onClick={handleClick}
        >
          Loading...
        </button>
      )}
    </div>
  );
}
